import styled from 'styled-components'
import { Input, Typography } from 'antd'

export const Wrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	@media (max-width: 460px) {
		padding: 0 16px;
	}
`
export const Paper = styled.div`
	display: flex;
	flex-direction: column;
	background-color: #ffffff;
	padding: 20px;
	border-radius: 21px;
	width: 100%;
`
export const ResultArea = styled(Input.TextArea)`
	display: flex;
	background-color: #ffffff;
	padding: 16px;
	border-radius: 21px;
`
export const HomeTitle = styled(Typography.Title)`
	width: 100%;
	text-align: center;
	color: #ffffff !important;
	font-size: 50px !important;
	margin-bottom: 60px !important;
	margin-top: 100px !important;

	@media (min-width: 768px) {
		margin-bottom: 60px !important;
		font-size: 60px !important;
	}
	@media (max-width: 768px) {
		margin-top: 60px !important;
		font-size: 50px !important;
	}
	@media (max-width: 400px) {
		margin-bottom: 30px !important;
		margin-top: 30px !important;
		font-size: 40px !important;
	}
`
