import React from 'react'
import { RouteComponentProps } from 'react-router'
import { isMobile } from 'mobile-device-detect'
import { Helmet } from 'react-helmet'

import { Button } from 'antd'
import styled from 'styled-components'
import icon from '../images/whatsapp.svg'

import { observer } from 'mobx-react-lite'
import AwayState from '../mobx/AwayState'

interface MatchParams {
	number: string
}

export default function Away(props: RouteComponentProps<MatchParams>) {
	const { number } = props.match.params
	const text = new URLSearchParams(props.location.search).get('text')?.replace(/ /g, '+')
	const link = `https://api.whatsapp.com/send/?phone=${number}${text ? `&text=${text}` : ''}`
	const phoneLink = `whatsapp://send/?phone=${number}${text ? `&text=${text}` : ''}`

	React.useEffect(() => {
		// AwayState.setLink(link)
		AwayState.setPhoneLink(phoneLink)

		AwayState.startTimeout()
	}, [])

	return (
		<Container>
			<Helmet title="Переадресация на WhatsApp" />
			<Main>
				<Title>Открывается приложение Ватсап</Title>
				<Icon src={icon} />
				{/* <ObserveTimer /> */}
				<Timer>Перенаправление...</Timer>
			</Main>
			<Footer>
				Создайте такой виджет бесплатно на{' '}
				<FooterLink type="link" href="https://wtsapp.online" target="_blank">
					wtsapp.online
				</FooterLink>
			</Footer>
		</Container>
	)
}

const ObserveTimer = observer(() => <Timer>Переход через {AwayState.seconds} с.</Timer>)

const Container = styled.div`
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding: 40px 0;

	background: rgb(0, 168, 255);
	background: linear-gradient(45deg, #00ff72 0%, #0089ff 100%);
`
const Main = styled.div`
	width: 100%;
	heigth: auto;
	flex-grow: 1;
	display: flex;
	align-items: center;
	flex-direction: column;
`
const Title = styled.h1`
	font-size: 2.2rem;
	color: #ffffff;
	text-align: center;
`
const Icon = styled.img`
	width: 160px;
	height: auto;
	margin: 40px 0;
`
const Timer = styled.div`
	font-size: 16px;
	color: #ffffff;
	margin: 40px 0;
`
const Footer = styled.div`
	width: 100%;
	text-align: center;
	font-size: 16px;
	color: #ffffff;
`
const FooterLink = styled(Button)`
	font-size: 16px;
	color: #a2e5ff;
	padding: 0;

	&:hover {
		color: #ffffff;
	}
	&:focus {
		color: #ffffff;
	}
	& > span {
		text-decoration: underline;
	}
`
