import { makeAutoObservable } from 'mobx'

class Result {
	prefix?: string = undefined
	text?: string = undefined
	phone?: string = undefined

	constructor() {
		makeAutoObservable(this)
	}

	setPrefix(value: string) {
		this.prefix = value
	}
	setText(value: string) {
		this.text = value
	}
	setPhone(value: string) {
		this.phone = value
	}
	get link() {
		const text = this.text && `?text=${this.text.replace(/ /g, '+')}`
		return `${window.location.origin}/${this.phone}${text || ''}`
	}
}

export default new Result()
