import React from 'react'
import { Form, Input, Button, message } from 'antd'
import Result from '../mobx/Result'
import Step from '../mobx/Step'

const layout = {
	labelCol: { span: 10 },
}

export default function InputsForm() {
	const [form] = Form.useForm()
	const onFinish = () => {
		Step.setStep(0)
	}

	const copyHandler = () => {
		navigator.clipboard.writeText(Result.link)
		message.success('Ссылка скопирована!')
	}

	return (
		<>
			<Form
				{...layout}
				form={form}
				name="createlink"
				onFinish={onFinish}
				initialValues={{
					prefix: '7',
				}}
				scrollToFirstError
				size="large"
			>
				<Form.Item name="text" label="Сгенерированная ссылка" style={{ marginBottom: 16, display: 'block' }}>
					<Input.TextArea readOnly={true} defaultValue={Result.link} style={{ minHeight: 160 }} />
				</Form.Item>
				<span style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
					<Button onClick={copyHandler} type="ghost" size="large" style={{ marginRight: 8 }}>
						Скопировать
					</Button>
					<Button htmlType="submit" type="primary" size="large">
						Назад
					</Button>
				</span>
			</Form>
		</>
	)
}
