import React, { useEffect, useState } from 'react'
import { Paper, HomeTitle, Wrapper } from './Styled'
import InputsForm from './InputsForm'
import Result from './Result'
import Step from '../mobx/Step'
import { observer } from 'mobx-react-lite'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'

export default function HomePage() {
	const [layout, setLayout] = useState('')

	useEffect(() => {
		fetch(process.env.PUBLIC_URL + '/layouts/1.html')
			.then(r => r.text())
			.then(r => setLayout(r))
	}, [])

	return (
		<Wrapper>
			<Helmet
				meta={[
					{
						name: 'description',
						content:
							'Наш конструктор поможем вам сделать ссылку на WhatsApp с текстом! Бесплатный конструктор ссылок Ватсап для Инстаграм.',
					},
				]}
				title="Сделать ссылку на Ватсап за 1 минуту"
			/>
			<HomeTitle level={1}>Сделать ссылку на Ватсап</HomeTitle>
			<Paper style={{ maxWidth: 500 }}>
				<StepFirst />
				<StepTwo />
			</Paper>
			<HTML dangerouslySetInnerHTML={{ __html: layout }} />
		</Wrapper>
	)
}

const StepFirst = observer(() => {
	return Step.step === 0 ? <InputsForm /> : <></>
})

const StepTwo = observer(() => {
	return Step.step === 1 ? <Result /> : <></>
})

const HTML = styled.div``
