import { makeAutoObservable } from 'mobx'

class Step {
	step: number = 0

	constructor() {
		makeAutoObservable(this)
	}

	setStep(value: number) {
		this.step = value
	}
}

export default new Step()
